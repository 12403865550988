import React from "react"
// import SlateEditor from "../components/slate-editor"
import styled from "styled-components"

const EditorPage = () => {
  return (
    <EditorWrapper>
      {/* <h1>Editor</h1> */}
      {/* <SlateEditor /> */}
      <h1>coming soon</h1>
    </EditorWrapper>
  )
}
export default EditorPage


const EditorWrapper = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 680px;
    margin-inline: auto;
    justify-content: center;
    padding: 24px;
    overflow-y: scroll;
    height: 50vh;
    font-family: var(--body-text-regular), sans-serif;
    border-left: 1px solid ${p => p.theme.borderColor};
    border-right: 1px solid ${p => p.theme.borderColor};
`
